import { Link } from "gatsby"
import React from "react"

import logo from "../images/cnp4w.png"
const Footer = () => {
  return (
    <footer className="py-12 bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-600">
        <div className="pb-10 pt-0 md:pt-6">
          <div className="space-y-10 md:space-y-0 md:grid lg:grid-cols-5 md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="lg:flex flex-col justify-center hidden">
              <div className="flex mb-6 flex-col">
                <Link to="/">
                  <img src={logo} alt="logo" className="lg:w-full lg:h-full" />
                </Link>
                <p className="text-sky-100 text-sm mt-4 text-center">
                  Un mondo di servizi, senza muoverti da casa
                </p>
              </div>
            </div>
            {/* Menù Principale */}
            <div className="flex flex-col">
              <img
                src={logo}
                alt="logo"
                className="w-2/4 h-auto flex lg:hidden mb-4"
              />
              <ul className="list-none">
                <li className="text-xl leading-6  text-gray-50 my-4 md:mt-0 md:mb-4">
                  Menù Principale
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/chi-siamo">Chi siamo</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/contatti">Contatti</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/news">News</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/account/profile">Area riservata</Link>
                </li>
              </ul>
            </div>
            {/* Servizi */}
            <div className="flex flex-col">
              <ul className="list-none">
                <li className="text-xl leading-6  text-gray-50 my-4 md:mt-0 md:mb-4">
                  Servizi
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300 ">
                  <Link to="/servizi/luce-gas">Luce e gas</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/telefonia">Telefonia</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/assicurazioni">Assicurazioni</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/conti-correnti">
                    Conti correnti e carte
                  </Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/crypto-trading">Crypto e trading</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/biglietti">Biglietti e prenotazioni</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/noleggio-auto">Noleggio auto</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/servizi/e-commerce">E-commerce</Link>
                </li>
              </ul>
            </div>
            {/* Link utili */}
            <div className="flex flex-col">
              <ul className="list-none">
                <li className="text-xl leading-7  text-gray-100 mb-4">
                  <Link to="/privacy">Link utili</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/privacy">Privacy</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/cookie-policy">Cookie policy</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/termini-e-condizioni">Termini e condizioni</Link>
                </li>
                <li className="text-base leading-7  text-gray-100 hover:text-gray-100 hover:underline hover:transition ease-in-out duration-300">
                  <Link to="/programma-fidelity">Programma fidelity</Link>
                </li>
              </ul>
            </div>
            {/* Contatti */}
            <div className="flex flex-col">
              <ul className="list-none">
                <li className="text-xl leading-6  text-gray-100 mb-4">
                  <Link to="/contatti">Contatti</Link>
                </li>
                <li className="text-sm leading-6  text-gray-100 break-normal hover:text-gray-100  hover:transition ease-in-out duration-300">
                  VIA DEI SETTE METRI 107/I CAP 00118 - ROMA (RM)
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100  hover:transition ease-in-out duration-300">
                  <a href="tel:+390659877456">+39 06 5987 7456</a>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100  hover:transition ease-in-out duration-300">
                  <a href="mailto:info@clicknpay.it">info@clicknpay.it</a>
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100  hover:transition ease-in-out duration-300">
                  P.I. & C.F.: 14641581005
                </li>
                <li className="text-sm leading-6  text-gray-100 hover:text-gray-100  hover:transition ease-in-out duration-300">
                  REA N° RM-1535310 IVASS RUI N°E000598091
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="text-center text-white pt-7">
        Copyright {new Date().getFullYear()} Click'n'pay © – di proprietà
        Tutorpoint s.r.l.s.
      </div>
    </footer>
  )
}

export default Footer
