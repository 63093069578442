import React, { useState } from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import ModalContact from "../components/Modal"

const Contatti = () => {
  const [values, setValues] = useState({
    name: "",
    emailFrom: "",
    message: "",
  })
  const [error, setError] = useState({})
  const handleChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const validateData = values => {
    const errors = {}
    if (!values.name.trim()) {
      errors.name = "Il nome è obbligatorio"
    }
    if (!values.emailFrom.trim()) {
      errors.emailFrom = "L'email è obbligatoria"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailFrom)
    ) {
      errors.emailFrom = "L'email non è valida"
    }
    if (!values.message) {
      errors.message = "Il messaggio è obbligatorio"
    } else if (values.message.length < 10) {
      errors.message = "Il messaggio deve essere di almeno 10 caratteri"
    }
    return errors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = validateData(values)
    setError(errors)
    if (Object.keys(errors).length === 0) {
      const response = await fetch(process.env.CLICK_API_CONTACTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (response.ok) {
        setValues({
          name: "",
          emailFrom: "",
          message: "",
        })
        setError({})
      }
    }
  }

  const [open, setOpen] = useState(false)
  const toggleModal = () => {
    setOpen(!open)
  }

  const disabled = !(
    values.name.trim() &&
    values.emailFrom.trim() &&
    values.emailFrom.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) &&
    values.emailFrom.length > 10 &&
    values.message.trim() &&
    values.message.length >= 10
  )
  return (
    <Layout>
      <Seo title="Contatti" />

      <section className=" bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5">
          <h2 className="mb-6 text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
            contatti
          </h2>

          <div className="lg:text-center">
            <div className="">
              <div className="space-y-10 md:space-y-0 md:grid lg:grid-cols-2 md:grid-cols-2 md:gap-x-16 md:gap-y-10">
                <div className="min-h-full flex items-center justify-center text-base md:text-xl">
                  <ul className="list-none text-sky-900">
                    <li className="leading-10 font-medium text-sky-900">
                      VIA DEI SETTE METRI 107/I CAP 00118 - ROMA (RM)
                    </li>
                    <li className="leading-10 font-medium text-sky-900 hover:text-sky-600  hover:transition ease-in-out duration-300">
                      <a href="tel:+390659877456">+39 06 5987 7456</a>
                    </li>
                    <li className="leading-10 font-medium text-sky-900 hover:text-sky-600  hover:transition ease-in-out duration-300">
                      <a href="mailto:info@clicknpay.it">info@clicknpay.it</a>
                    </li>
                    <li className="leading-10 font-medium  text-sky-900 ">
                      P.I. & C.F.: 14641581005
                    </li>
                    <li className="leading-10 font-medium  text-sky-900 ">
                      REA N° RM-1535310 IVASS RUI N°E000598091
                    </li>
                  </ul>
                </div>
                <div className="min-h-full flex items-center justify-center">
                  <div className="max-w-md w-full space-y-8">
                    <form className="mt-8 space-y-10" onSubmit={handleSubmit}>
                      <input
                        type="hidden"
                        name="remember"
                        defaultValue="true"
                      />
                      <div className="rounded-md shadow-sm text-base -space-y-px">
                        <div>
                          <label htmlFor="name" className="sr-only">
                            Nome e cogname
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="name"
                            value={values.name}
                            onChange={handleChange}
                            className="appearance-none rounded-none relative block w-full px-3 py-2 md:py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-amber-500 focus:border-amber-500 focus:z-10 sm:text-sm"
                            placeholder="Nome e cognome"
                          />
                        </div>
                        {error.name && (
                          <p className="text-red-600 text-xs italic">
                            {error.name}
                          </p>
                        )}
                        <div>
                          <label htmlFor="emailFrom" className="sr-only">
                            Email
                          </label>
                          <input
                            id="emailFrom"
                            name="emailFrom"
                            type="email"
                            value={values.emailFrom}
                            onChange={handleChange}
                            className="appearance-none mb-6 rounded-none relative block w-full px-3 py-2 md:py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-amber-500 focus:border-amber-500 focus:z-10 sm:text-sm"
                            placeholder="Email"
                          />
                        </div>
                        {error.emailFrom && (
                          <p className="text-red-600 text-xs italic">
                            {error.emailFrom}
                          </p>
                        )}
                        <textarea
                          id="message"
                          name="message"
                          rows={6}
                          value={values.message}
                          onChange={handleChange}
                          className="shadow-sm text-gray-900 focus:ring-amber-500 focus:border-amber-500 mt-6 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Il tuo messaggio"
                        />
                        {error.message && (
                          <p className="text-red-600 text-xs italic">
                            {error.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <button
                          disabled={disabled}
                          onClick={toggleModal}
                          type="submit"
                          className={
                            disabled
                              ? "group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-gray-400 bg-super/30 cursor-not-allowed"
                              : "group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-sky-900 bg-super hover:bg-super/80 hover:transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          }
                        >
                          {disabled ? (
                            ""
                          ) : (
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                              <IoCheckmarkDoneSharp
                                className="h-5 w-5 text-sky-900 group-hover:text-sky-900"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                          <span className="text-sm">Invia messaggio</span>
                        </button>
                        <ModalContact
                          open={open}
                          setOpen={setOpen}
                          title="Grazie per averci contattato!"
                          message="Il messaggio è stato inviato con successo, ti risponderemo il prima possibile."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contatti
