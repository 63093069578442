import React, { Fragment } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "../services/auth"
import { Popover, Transition, Menu } from "@headlessui/react"
import {
  IoCarSportOutline,
  IoTrendingUpSharp,
  IoBulbOutline,
  IoPhonePortraitOutline,
  IoCardOutline,
  IoShieldCheckmarkOutline,
  IoMenuSharp,
  IoClose,
  IoTicketSharp,
  IoHelpBuoySharp
} from "react-icons/io5"
import { ChevronDownIcon } from "@heroicons/react/solid"
import Logo from "../images/cnp4.png"

const servizi = [
  {
    name: "Luce e gas",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/luce-gas",
    icon: IoBulbOutline,
  },
  {
    name: "Telefonia",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/telefonia",
    icon: IoPhonePortraitOutline,
  },
  {
    name: "Assicurazioni",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/assicurazioni",
    icon: IoShieldCheckmarkOutline,
  },
  {
    name: "Conti correnti e carte",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/conti-correnti",
    icon: IoCardOutline,
  },
  {
    name: "Crypto e trading",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/crypto-trading",
    icon: IoTrendingUpSharp,
  },
  {
    name: "Biglietti e prenotazioni",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/biglietti",
    icon: IoTicketSharp,
  },
  {
    name: "Noleggio auto",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/noleggio-auto",
    icon: IoCarSportOutline,
  },
  {
    name: "E-commerce",
    description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    href: "/servizi/e-commerce",
    icon: IoHelpBuoySharp,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const activeStyles = {
  color: "#404040",
  fontWeight: 600,
}
const Header = () => {
  const { isAuthenticated, loginWithRedirect, logout, user, gaClientId } = useAuth0()
  return (
    <header className="bg-white sticky top-0 z-50 shadow-md">
      <Popover>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-4 lg:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <img className="h-6 w-auto sm:h-8" src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-neutral-700 hover:text-neutral-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-super">
                <span className="sr-only">Open menu</span>
                <IoMenuSharp className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group
              as="nav"
              className="hidden lg:flex items-center space-x-10"
            >
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open
                          ? "text-neutral-700 font-semibold"
                          : "text-gray-500",
                        "group super-menu-item"
                      )}
                    >
                      <span>servizi</span>
                      <ChevronDownIcon
                        className={classNames(
                          open
                            ? "text-neutral-700 font-medium"
                            : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-neutral-700 font-medium"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {servizi.map(item => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <item.icon
                                  className="flex-shrink-0 h-6 w-6 text-super"
                                  aria-hidden="true"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                to="/chi-siamo"
                className="group super-menu-item"
                activeStyle={activeStyles}
              >
                Chi siamo
              </Link>
              <Link
                to="/contatti"
                className="group super-menu-item"
                activeStyle={activeStyles}
              >
                Contatti
              </Link>
              <Link
                to="/news"
                className="group super-menu-item"
                activeStyle={activeStyles}
              >
                News
              </Link>
            </Popover.Group>
            <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
              {isAuthenticated ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="super-btn">
                      Ciao
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-900 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/account/profile"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Area riservata
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/"
                              onClick={() => logout()}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Uscita
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <button
                  className="super-btn"
                  onClick={() => loginWithRedirect({gaClientId: gaClientId})}
                >
                  Iscriviti
                </button>
              )}
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute overflow-auto bg-white top-0 inset-x-0 transition transform origin-top-right lg:hidden"
          >
            <div className="ring-opacity-5 bg-white divide-y-2 divide-gray-50 h-screen">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-6 w-auto" src={Logo} alt="logo" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-super">
                      <span className="sr-only">Close menu</span>
                      <IoClose className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {servizi.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-super"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <Link
                    to="/chi-siamo"
                    className="group super-menu-item"
                    activeStyle={activeStyles}
                  >
                    Chi siamo
                  </Link>

                  <Link
                    to="/contatti"
                    className="group super-menu-item"
                    activeStyle={activeStyles}
                  >
                    Contatti
                  </Link>
                  <Link
                    to="/news"
                    className="group super-menu-item"
                    activeStyle={activeStyles}
                  >
                    News
                  </Link>
                </div>
                <div>
                  <hr />
                  {isAuthenticated ? (
                    <div>
                      <div className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 uppercase">
                        Ciao {user && user.name}
                      </div>
                      <Link
                        to="/account/profile"
                        className=" text-gray-900  block  py-2 text-sm"
                      >
                        Area riservata
                      </Link>
                      <Link
                        to="/"
                        onClick={() => logout()}
                        className=" text-gray-900  block  py-2 text-sm"
                      >
                        Uscita
                      </Link>
                    </div>
                  ) : (
                    <button
                      className="super-btn ml-0 w-full mt-2"
                      onClick={() => loginWithRedirect({gaClientId: gaClientId})}
                    >
                      Iscriviti
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}

export default Header
